import React, { useCallback, useState } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Pagination } from "@mui/material";

export interface IColumnTable {
  title: string;
  sort?: boolean;
  field?: string;
  className?: string;
  render?: Function;
}

export interface IPaginateTable {
  total: number;
  limit: number;
  page: number;
}

interface Props {
  data: any[];
  columns: IColumnTable[];
  sort?: Function;
  paginate?: IPaginateTable;
  onChangePage?: Function;
}

interface PropsSort {
  type: number;
}
function IconSort({ type }: PropsSort) {
  switch (type) {
    case 1:
      return <ArrowUpwardIcon />;
    case -1:
      return <ArrowDownwardIcon />;
    default:
      return <></>;
  }
}

export default function Table({
  data = [],
  columns = [],
  sort,
  paginate,
  onChangePage,
}: Props) {
  const [typeSort, setTypeSort] = useState(0);
  const [selectSort, setSelectSort] = useState();
  const onSort = (title, field) => {
    if (field === undefined) {
      console.log("Error =>", "Please set field for sort!");
      return;
    }
    let sortTmp = 0;
    if (selectSort === title) {
      if (typeSort === 0) {
        sortTmp = 1;
      }
      if (typeSort === 1) {
        sortTmp = -1;
      }
      if (typeSort === -1) {
        sortTmp = 0;
      }
    } else {
      sortTmp = 1;
    }
    setTypeSort(sortTmp);
    setSelectSort(title);
    if (sort) {
      sort(field, sortTmp);
    }
  };
  const calculatorTotal = useCallback(() => {
    if (!paginate?.total || !paginate?.limit) {
      console.log("Error =>", "Please value transmission total and limit at paginate!");
      return 0;
    }
    return +paginate.total % +paginate.limit === 0
      ? +paginate.total / +paginate.limit
      : parseInt((+paginate?.total / +paginate?.limit).toString()) + 1;
  }, [paginate?.total, paginate?.limit])

  return (
    <div className="component_table">
      <div></div>
      <div>
        <table>
          <thead>
            <tr>
              {columns?.map((column, index) => (
                <th
                  key={index}
                  onClick={() => onSort(column.title, column?.field)}
                  className={column.sort ? "cursor-pointer" : ""}
                >
                  <div className={column.className ? column.className : ""}>
                    {column.title}{" "}
                    {column?.sort && selectSort === column.title && (
                      <IconSort type={typeSort} />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr key={index}>
                  {columns?.map((column, i) => {
                    return (
                      <td key={index + "-" + i} className={column.position ? column.position : ""}>
                        {column?.render
                          ? column?.render(
                            column?.field ? item?.[column?.field] : undefined,
                            item,
                            data,
                            index,
                            i
                          )
                          : column?.field
                            ? item?.[column?.field]
                            : "Get value at value error"}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {data.length === 0 && (
              <tr>
                <td className="center" colSpan={columns.length}>Not found data</td>
              </tr>
            )}
          </tbody>
        </table>
        {paginate && calculatorTotal() > 0 && (
          <div className="paginate">
            <Pagination
              color="primary"
              count={calculatorTotal()}
              onChange={(_, page) =>
                onChangePage
                  ? onChangePage(page)
                  : console.log("Please add function onChangePage!", page)
              }
              page={paginate.page}
              showFirstButton
              showLastButton
            />
          </div>
        )}
      </div>
    </div>
  );
}
