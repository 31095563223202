import axios from "axios";

const path = "packages/";

const get = async (filter = {}) => {
  return (await axios.get(path, { params: filter })).data;
}

const getById = async (id) => {
  return (await axios.get(path + id)).data
}

const create = async (category) => {
  return (await axios.post(path, category)).data;
}

const update = async (id, category) => {
  return (await axios.patch(path + id, category)).data;
}


const deletePackage = async (id) => {
  return (await axios.delete(path + id)).data;
}

export const packageAPI = {
  get,
  getById,
  create,
  update,
  deletePackage,
}