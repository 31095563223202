/* eslint-disable react/react-in-jsx-scope */
import { FILE_TYPE } from "../../consts/file_type";
import PropTypes from "prop-types";

Image.propTypes = {
    src: PropTypes.string,
    type: PropTypes.string,
    alt: PropTypes.string
}

function Image({ src, type, alt = "", className, ...props }) {

    switch (type) {
        case FILE_TYPE.IMAGE:
            return <div className={"table_thumnail thumbnail " + (className ? className : "")}><img src={src} alt={alt} {...props} /></div>
        case FILE_TYPE.VIDEO:
            return <div className={"table_thumnail " + (className ? className : "")}><video width="100%" src={src} alt={alt} controls={true} {...props} /></div>
        case FILE_TYPE.AUDIO:
            return <div className={"table_thumnail " + (className ? className : "")}><audio controls style={{ width: "100%" }} {...props} >  <source src={src} type="audio/mpeg" /></audio></div>
        default:
            return "Other";
    }
}

export default Image;