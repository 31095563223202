import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import PermIdentity from "@material-ui/icons/PermIdentity";
import Card from "../../components/card/card.js";
import CardHeader from "../../components/card/cardHeader.js";
import CardIcon from "../../components/card/cardIcon.js";
import styles from "../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { useWeb3 } from "../../hooks/useWeb3";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import Image from "../../components/image/image";
import { FILE_TYPE } from "../../consts/file_type";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { pinFileToIPFS } from "../../helper/ipfs";
import { DialogContent, DialogTitle } from "@material-ui/core";
import Slide from "@mui/material/Slide";
import { authAPI } from "../../apis/auth.api";
import { userAPI } from "../../apis/user.api.js";
import { getError } from "../../helper/getError.js";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserProfile() {
  const history = useHistory();
  const classes = useStyles();
  const { user, logout, me } = useWeb3();
  const [tabs, setTabs] = useState("1");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState();
  const [form, setForm] = useState({
    username: "",
    name: "",
    avatar: "",
    password: "",
    confirmPassword: "",
  });
  const [formLogin, setFormLogin] = useState({
    username: "",
    password: "",
  });
  const handleChangeTabs = (event, newValue) => {
    setTabs(newValue);
  };

  const onChangeFile = (e) => {
    try {
      const file = e.target.files[0];
      const fileType = file.type.split("/")[0];
      if (fileType !== FILE_TYPE.IMAGE) {
        toast.error("Please select file type image!");
      } else {
        const reader = new FileReader();
        reader.onload = (rs) => {
          setForm({
            ...form,
            avatar: rs.target.result,
          });
        };
        reader.readAsDataURL(file);
        setFile(file);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateInfo = (form) => {
    if (form.username.length === 0) {
      toast.error("Please enter username!");
      return false;
    }
    if (form.username.length > 50) {
      toast.error("Please enter username less than 50 characters!");
      return false;
    }
    if (form.name.length === 0) {
      toast.error("Please enter name!");
      return false;
    }
    if (form.name.length > 50) {
      toast.error("Please enter name less than 50 characters!");
      return false;
    }
    return true;
  };

  const validatePassword = (form) => {
    if (form.password.length === 0) {
      toast.error("Please enter password!");
      return false;
    }
    if (form.username.length > 50) {
      toast.error("Please enter password less than 50 characters!");
      return false;
    }
    if (form.confirmPassword.length > 50) {
      toast.error("Please enter confirm password less than 50 characters!");
      return false;
    }
    if (form.confirmPassword !== form.password) {
      toast.error("Confirm password incorrect!");
      return false;
    }
    return true;
  };

  const onUpdate = async () => {
    try {
      if (!validateInfo(form)) return;
      setLoading(true);
      let tmp = {
        username: form.username,
        name: form.name
      };
      if (file) {
        const url = await pinFileToIPFS(file);
        tmp = {
          ...tmp,
          avatar: url,
        };
      }
      console.log(tmp);
      await userAPI.update(user?._id, tmp);
      await me();
      toast.success("Update success!");
      history.goBack();
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const onChangePassword = async () => {
    try {
      if (!validatePassword(form)) return;
      setOpen(true);
    } catch (error) {
      console.log(error);
    }
  };

  const login = async () => {
    try {
      await authAPI.login(formLogin);
      await userAPI.changePassword(user?._id, {
        password: form.password,
        confirmPassword: form.confirmPassword,
      });
      toast.success("Change password success! Please login again!");
      logout();
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    if (user) {
      delete user.password;
      setForm({
        ...form,
        ...user,
      });
    }
  }, [user]);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabs}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Edit Profile - <small>Complete your profile here</small>
              </h4>
            </CardHeader>
            <Box
              sx={{
                borderColor: "divider",
                padding: "0 40px",
                margin: "10px 0 0 0",
              }}
            >
              <TabList
                onChange={handleChangeTabs}
                aria-label="lab API tabs example"
              >
                <Tab label="User" value="1" />
                <Tab label="Password" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container spacing={2} columns={3}>
                <Grid item md={3} lg={1}>
                  <label
                    htmlFor="file"
                    className="cursor-pointer d-flex align-items-center justify-content-center"
                  >
                    <Image
                      className={
                        "d-flex justify-content-center align-items-center h200 w200 max-w200 max-h200"
                      }
                      src={form?.avatar || "/user.jpg"}
                      type={FILE_TYPE.IMAGE}
                    />
                  </label>
                  <input
                    onChange={onChangeFile}
                    type={"file"}
                    accept="image/*"
                    className="d-none"
                    id="file"
                  />
                </Grid>
                <Grid item md={3} lg={2}>
                  <Grid container columns={1} rowSpacing={2}>
                    <Grid item md={1}>
                      <FormControl fullWidth>
                        <TextField
                          label="Username"
                          variant="standard"
                          value={form?.username}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              username: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={1}>
                      <FormControl fullWidth>
                        <TextField
                          label="Display name"
                          variant="standard"
                          value={form?.name}
                          onChange={(e) =>
                            setForm({
                              ...form,
                              name: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={1}>
                      <Button
                        variant="contained"
                        sx={{ marginRight: 1 }}
                        onClick={onUpdate}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        color="inherit"
                        onClick={() => history.goBack()}
                      >
                        Go back
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Please login!"}</DialogTitle>
                <DialogContent>
                  <Grid container columns={1} spacing={2}>
                    <Grid item md={1}>
                      <FormControl fullWidth>
                        <TextField
                          label="Username"
                          variant="standard"
                          value={formLogin?.username}
                          onChange={(e) =>
                            setFormLogin({
                              ...formLogin,
                              username: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={1}>
                      <FormControl fullWidth>
                        <TextField
                          label="Password"
                          variant="standard"
                          type={"password"}
                          value={formLogin?.password}
                          onChange={(e) =>
                            setFormLogin({
                              ...formLogin,
                              password: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => login()}>Login</Button>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                </DialogActions>
              </Dialog>
              <Grid container columns={2} spacing={2}>
                <Grid item md={1}>
                  <FormControl fullWidth>
                    <TextField
                      label="Password"
                      variant="standard"
                      type={"password"}
                      value={form?.password}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          password: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item md={1}>
                  <FormControl fullWidth>
                    <TextField
                      label="Confirm password"
                      variant="standard"
                      type={"password"}
                      value={form?.confirmPassword}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          confirmPassword: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <Button
                    variant="contained"
                    sx={{ marginRight: 1 }}
                    onClick={onChangePassword}
                  >
                    Change
                  </Button>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => history.goBack()}
                  >
                    Go back
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
          </Card>
        </TabContext>
      </Box>
    </div>
  );
}
