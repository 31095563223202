import {
    Button,
    DialogActions,
    FormControl,
    Grid,
    MenuItem,
    TextField,
  } from "@mui/material";
  import { useEffect, useState } from "react";
  import { toast } from "react-toastify";
  import Dialog from "../../components/dialog";
  import { getError } from "../../helper/getError";
  import { useLoading } from "../../hooks/useLoading";
  import { listingAPI } from "../../apis/listing.api";
  
  const initForm = {
    property: "",
    price: "",
    seller: "",
    description: "",
    options: [],
    status: "",
    verify: "",
  };
  
  export default function FormRent({
    isOpen = false,
    onClose,
    _id,
    onRefetch,
  }) {
    const { setLoading } = useLoading();
    const [form, setForm] = useState(initForm);
    const [options, setOptions] = useState([]);
  
    const onUpdate = async (_id) => {
      try {
        setLoading(true);
        await listingAPI.update(_id, { ...form, option: options });
        if (onRefetch) await onRefetch();
        setLoading(false);
        toast.success("Updated listing success!");
        onClose();
      } catch (error) {
        setLoading(false);
        getError(error);
      }
    };
  
    const onSubmit = () => {
      if (_id) {
        onUpdate(_id);
      }
    };
  
    const fetchData = async (_id) => {
      try {
        const { seller, property, ...response } = await listingAPI.getById(_id);
        setForm({ seller: seller.name, property: property.name, ...response });
        setOptions(response?.option);
      } catch (error) {
        getError(error);
      }
    };
    useEffect(() => {
      setForm(initForm);
    }, [isOpen]);
  
    useEffect(() => {
      setOptions([]);
    }, [onClose]);
  
    useEffect(() => {
      let flag = true;
      if ((flag === true, _id)) {
        fetchData(_id);
      }
      return () => {
        flag = false;
      };
    }, [_id]);
    return (
      <Dialog
        maxWidth="md"
        title={"View Detail"}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <TextField
                variant="standard"
                label="Property"
                value={form?.property}
              />
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <TextField
                placeholder="Enter price"
                label={"Price"}
                value={form?.price}
                variant="standard"
              />
            </FormControl>
          </Grid>
  
          <Grid item md={12} xs={12}>
            <FormControl fullWidth>
              <TextField
                placeholder="Enter seller"
                label={"Seller"}
                value={form?.seller}
                variant="standard"
              />
            </FormControl>
          </Grid>
  
          <Grid item md={12} xs={12}>
            <FormControl fullWidth>
              <TextField
                placeholder="Enter description"
                multiline
                rows={5}
                label="Description"
                value={form.description || "Nothing"}
                variant="standard"
              />
            </FormControl>
          </Grid>
  
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                variant="standard"
                label="Status"
                value={form.status}
                onChange={(e) => setForm({ ...form, status: e.target.value })}
              >
                <MenuItem value={true} key={1}>
                  Ready
                </MenuItem>
                <MenuItem value={false} key={1}>
                  Not ready
                </MenuItem>
              </TextField>
            </FormControl>
          </Grid>
  
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                variant="standard"
                label="Verify"
                value={form.verify}
                onChange={(e) => setForm({ ...form, verify: e.target.value })}
              >
                <MenuItem value={true} key={1}>
                  Posting
                </MenuItem>
                <MenuItem value={false} key={1}>
                  Remove post
                </MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          
        </Grid>
        <DialogActions sx={{ mt: 2 }}>
          <Button variant="contained" onClick={() => onSubmit()}>
            UPDATE
          </Button>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  