import {
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DeleteDialog from "../../components/dialog/delete.dialog";
import Table from "../../components/table/table";
import { getError } from "../../helper/getError";
import { useLoading } from "../../hooks/useLoading";
import FormProperty from "./form_property";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { propertyAPI } from "../../apis/property.api";
import Image from "../../components/image/image";
import { categoryAPI } from "../../apis/category.api";
export default function Property() {
  const limit = 10;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState();
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [_id, set_id] = useState();
  const [titleDelete, setTitleDelete] = useState("");
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const { setLoading } = useLoading();

  const fetchCategory = async () => {
    try {
      const response = await categoryAPI.get();
      setCategories(response?.items);
    } catch (error) {
      getError(error);
    }
  };

  const openForm = (id) => {
    setIsOpen(true);
    if (id) {
      set_id(id);
    }
  };

  const closeForm = () => {
    setIsOpen(false);
    if (_id) {
      set_id();
    }
  };

  const fetchData = async () => {
    try {
      const response = await propertyAPI.get({
        limit,
        page,
        search,
        category,
        ...sort,
      });
      setData(response.items);
      setTotal(response.paginate.count);
    } catch (error) {
      getError(error);
    }
  };

  const paginate = {
    total,
    limit,
    page,
  };
  console.log(data);

  const columns = [
    {
      title: "STT",
      sort: true,
      render: (_, __, ___, index) => index + 1,
    },
    {
      title: "Image",
      position: "min-w100 img",
      render: (_, row) => <Image src={row?.img} type={row?.fileType} />,
    },
    {
      title: "Name",
      sort: true,
      field: "name",
    },
    {
      title: "Owner",
      sort: true,
      render: (_, row) => row?.owner?.name,
    },
    {
      title: "Category",
      sort: true,
      render: (_, row) => row?.category?.name,
    },
    {
      title: "State",
      sort: true,
      render: (_, row) => row?.state?.name,
    },
    {
      title: "City",
      sort: true,
      render: (_, row) => row?.city?.name,
    },
    {
      title: "Area",
      sort: true,
      field: "area",
      render: (value) => (
        <span>
          {value} m<sup>2</sup>
        </span>
      ),
    },
    {
      title: "",
      position: "end",
      field: "_id",
      render: (value, row) => (
        <>
          <IconButton
            color="success"
            onClick={() => {
              openForm(value);
            }}
          >
            <VisibilityOutlinedIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              openFormDelte(value, row?.name);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const openFormDelte = (_id, title) => {
    setIsOpenDelete(true);
    set_id(_id);
    setTitleDelete("Do you want delete <<" + title + ">>?");
  };

  const onDelete = async (_id) => {
    try {
      setLoading(true);
      await propertyAPI.trash(_id);
      await fetchData();
      setIsOpenDelete(false);
      setLoading(false);
      toast.success("Delete success!");
    } catch (error) {
      getError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    let flag = true;
    flag === true && fetchData();
    return () => {
      flag = false;
    };
  }, [page, sort, search, category]);

  useEffect(() => {
    let flag = true;
    flag === true && fetchCategory();
    return () => {
      flag = false;
    };
  }, []);

  return (
    <div className="main-content">
      <DeleteDialog
        onSubmit={() => onDelete(_id)}
        isOpen={isOpenDelete}
        title={titleDelete}
        onClose={() => setIsOpenDelete(false)}
      />
      <FormProperty
        _id={_id}
        isOpen={isOpen}
        onClose={closeForm}
        onRefetch={() => {
          fetchData();
        }}
      />
      <Grid container spacing={2} sx={{ justifyContent: "flex-end" }}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              select
              variant="standard"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              label="Category"
              placeholder="Enter name ..."
            >
              {categories?.map((category) => (
                <MenuItem key={category?._id} value={category?._id}>
                  {category?.name}
                </MenuItem>
              ))}
              {categories?.length === 0 && (
                <MenuItem value="">Not found data!</MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              variant="standard"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              label="Search"
              placeholder="Enter name ..."
            />
          </FormControl>
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={(field, type) => {
          if (+type === 0) {
            setSort();
          } else {
            setSort({
              sortBy: field,
              sortType: type,
            });
          }
        }}
        paginate={paginate}
        onChangePage={(p) => setPage(p)}
      />
    </div>
  );
}
