import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/icons/Menu";
import MoreVert from "@material-ui/icons/MoreVert";
import ViewList from "@material-ui/icons/ViewList";
import AdminNavbarLinks from "./adminNavbarLinks";
import Button from "../customButtons/button.js";
import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import { styled, alpha } from "@mui/material/styles";
import { default as MenuDrop } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import AddLinkIcon from "@mui/icons-material/AddLink";
import LogoutIcon from "@mui/icons-material/Logout";
import { jwtManager } from "../../helper/jwtManager";
import { useWeb3 } from "../../hooks/useWeb3";
import { authAPI } from "../../apis/auth.api";

const useStyles = makeStyles(styles);
export default function AdminNavbar(props) {
    const classes = useStyles();
    const { color, rtlActive, brandText } = props;
    const appBarClasses = cx({
        [" " + classes[color]]: color,
    });
    const sidebarMinimize =
        classes.sidebarMinimize +
        " " +
        cx({
            [classes.sidebarMinimizeRTL]: rtlActive,
        });

    return (
        <AppBar className={classes.appBar + appBarClasses}>
            <Toolbar className={classes.container}>
                <Hidden smDown implementation="css">
                    <div className={sidebarMinimize}>
                        {props.miniActive ? (
                            <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                                <ViewList className={classes.sidebarMiniIcon} />
                            </Button>
                        ) : (
                            <Button justIcon round color="white" onClick={props.sidebarMinimize}>
                                <MoreVert className={classes.sidebarMiniIcon} />
                            </Button>
                        )}
                    </div>
                </Hidden>
                <div className={classes.brand}>
                    {/* Here we create navbar brand, based on route name */}
                    <Button href="#" className={classes.title} color="transparent">
                        {brandText}
                    </Button>

                    <MenuDropDown />
                </div>
                <Hidden smDown implementation="css">
                    <AdminNavbarLinks rtlActive={rtlActive} />
                </Hidden>
                <Hidden mdUp implementation="css">
                    <Button
                        className={classes.appResponsive}
                        color="transparent"
                        justIcon
                        aria-label="open drawer"
                        onClick={props.handleDrawerToggle}
                    >
                        <Menu />
                    </Button>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

AdminNavbar.propTypes = {
    color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
    rtlActive: PropTypes.bool,
    brandText: PropTypes.string,
    miniActive: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    sidebarMinimize: PropTypes.func,
};

const StyledMenu = styled((props) => (
    <MenuDrop
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const MenuDropDown = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [wallet, setWallet] = React.useState();
    const [avatar, setAvatar] = useState();
    const { logout, login, user } = useWeb3();
    // const { user } = useWeb3();

    useEffect(() => {
        (async () => {
            const token = localStorage.getItem("token");
            if (token) {
                const { data } = await authAPI.me();
                setAvatar(data);
            }
        })();
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    async function connectWallet() {
        login();
    }

    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? "demo-positioned-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className={classes.buttonDropDown}
            >
                <div
                    style={{
                        width: "50px",
                        height: "50px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        background: "none"
                    }}
                >
                    <img
                        src={user?.avatar || "/logo/png"}
                        style={{
                            maxWidth: "80%",
                            maxHeight: "100%",
                        }}
                        alt="..."
                    />
                </div>
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <Link to={"/admin/profile"} className={classes.navLink}>
                    <MenuItem onClick={handleClose} disableRipple>
                        <EditIcon />
                        Edit Profile
                    </MenuItem>
                </Link>

                <MenuItem onClick={handleClose} disableRipple>
                    <Link
                        to={"/auth/login"}
                        onClick={() => jwtManager.clear()}
                        className={classes.navLink}
                    >
                        <LogoutIcon />
                        Logout
                    </Link>
                </MenuItem>
            </StyledMenu>
        </div>
    );
};
