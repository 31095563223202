import axios from "axios";

const path = "city/";

const getByCountryAndState = async (country, state) => {
  return (await axios.get(path + `country/${country}/state/${state}`)).data;
}

const getById = async (id) => {
  return (await axios.get(path + id)).data
}
export const cityAPI = {
  getByCountryAndState,
  getById
}