import {
  FormControl,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import Table from "../../components/table/table";
import { getError } from "../../helper/getError";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteDialog from "../../components/dialog/delete.dialog";
import { useLoading } from "../../hooks/useLoading";
import { toast } from "react-toastify";
import { listingAPI } from "../../apis/listing.api";
import FormListing from "./form_listing";
export default function Listing() {
  const limit = 10;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState();
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [_id, set_id] = useState();
  const [titleDelete, setTitleDelete] = useState("");
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const { setLoading } = useLoading();
  const openForm = (id) => {
    setIsOpen(true);
    if (id) {
      set_id(id);
    }
  };

  const closeForm = () => {
    setIsOpen(false);
    if (_id) {
      set_id();
    }
  };

  const fetchData = async () => {
    try {
      const response = await listingAPI.get({
        limit,
        page,
        search,
        ...sort,
      });
      setData(response.items);
      setTotal(response.paginate.count);
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    let flag = true;
    flag === true && fetchData();
    return () => {
      flag = false;
    };
  }, [page, sort, search]);

  const paginate = {
    total,
    limit,
    page,
  };

  const columns = [
    {
      title: "STT",
      sort: true,
      render: (_, __, ___, index) => index + 1,
    },
    {
      title: "Property",
      sort: true,
      render: (_, row) => row?.property?.name,
    },
    {
      title: "Price",
      sort: true,
      field: "price",
    },
    {
      title: "Seller",
      sort: true,
      render: (_, row) => row?.seller?.name,
    },
    {
      title: "",
      position: "end",
      field: "_id",
      render: (value, row) => (
        <>
          <IconButton
            color="success"
            onClick={() => {
              openForm(value);
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            color="error"
            onClick={() => {
              openFormDelte(value, row?.name);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const openFormDelte = (_id, title) => {
    setIsOpenDelete(true);
    set_id(_id);
    setTitleDelete("Do you want delete <<" + title + ">>?");
  };

  const onDelete = async (_id) => {
    try {
      setLoading(true);
      await listingAPI.trash(_id);
      await fetchData();
      setIsOpenDelete(false);
      setLoading(false);
      toast.success("Delete success!");
    } catch (error) {
      getError(error);
    }
  };

  return (
    <div>
      <DeleteDialog
        onSubmit={() => onDelete(_id)}
        isOpen={isOpenDelete}
        title={titleDelete}
        onClose={() => setIsOpenDelete(false)}
      />
      <FormListing
        _id={_id}
        isOpen={isOpen}
        onClose={closeForm}
        onRefetch={() => {
          fetchData();
        }}
      />
      <Grid container spacing={2} sx={{ justifyContent: "flex-end" }}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              variant="standard"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              label="Search"
              placeholder="Enter seller ..."
            />
          </FormControl>
        </Grid>
      </Grid>
      <Table
        data={data}
        columns={columns}
        sort={(field, type) => {
          if (+type === 0) {
            setSort();
          } else {
            setSort({
              sortBy: field,
              sortType: type,
            });
          }
        }}
        paginate={paginate}
        onChangePage={(p) => setPage(p)}
      />
    </div>
  );
}
