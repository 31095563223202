import { FormControl } from "@material-ui/core";
import {
  Grid, TextField,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { useEffect, useState } from "react";
import { packageAPI } from "../../apis/package.api";
import Dialog from "../../components/dialog";
import { getError } from "../../helper/getError";
import AddButton from "./AddButton";

const initForm = {
  name: "",
  price: 0,
  numOfDay: 0,
  postLimit: 0,
  content: []
};

export default function FormPackage({
  isOpen = false,
  onClose,
  _id,
  onRefetch,
}) {
  const [form, setForm] = useState(initForm);

  const fetchData = async (_id) => {
    try {
      setForm(await packageAPI.getById(_id));
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    setForm(initForm);
  }, [isOpen]);

  useEffect(() => {
    let flag = true;
    if (_id && flag === true) {
      fetchData(_id);
    }
    return () => {
      flag = false;
    };
  }, [_id]);

  const displayList = () => {
    const arr = []
    for (let i = 0; i < 6; i++) {
      arr.push(
        <Grid item md={12}>
          <FormControl fullWidth>
            <TextField
              label={`Content ${i+1}`}
              variant="standard"
              value={form.content[i]}
              onChange={(e) => {
                let arrContent = form.content;
                arrContent[i] = e.target.value;
                setForm({ ...form, content: arrContent})
              }}
            />
          </FormControl>
        </Grid>

        
      )
    }
    return arr;

  }
  console.log("form", form)
  return (
    <Dialog
      title={_id ? "Update Package" : "Add Package"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Grid container spacing={2} md={12} xs={12} fullWidth>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              required
              label="Name"
              variant="standard"
              value={form.name}
              disabled={_id ? true : false}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              required
              label="Number of days"
              variant="standard"
              value={form.numOfDay}
              disabled={_id ? true : false}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setForm({ ...form, numOfDay: isNaN(value) ? e.target.value : value })
              }}
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              required
              label="Price"
              variant="standard"
              value={form.price}
              onChange={(e) => setForm({ ...form, price: e.target.value })}
            />
          </FormControl>
        </Grid>

        <Grid item md={12}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              required
              label="Number of Post"
              variant="standard"
              value={form.postLimit}
              onChange={(e) => {
                const value = parseInt(e.target.value);
                setForm({ ...form, postLimit: isNaN(value) ? e.target.value : value })
              }}
            />
          </FormControl>
        </Grid>

        {displayList()}

        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel>Active</InputLabel>
            <Select
              value={form.active}
              onChange={(e) => setForm({ ...form, active: e.target.value })}
              variant="standard"
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <AddButton onClose={onClose} _id={_id} onRefetch={onRefetch} form={form} />
    </Dialog>
  );
}
