import {
  Button,
  DialogActions,
  FormControl,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { categoryAPI } from "../../apis/category.api";
import { cityAPI } from "../../apis/city.api";
import { stateAPI } from "../../apis/state.api";
import Dialog from "../../components/dialog";
import ImageForm from "../../components/image/image_form";
import { TYPE_CATEGORY } from "../../consts/type_category.const";
import { getError } from "../../helper/getError";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import { propertyAPI } from "../../apis/property.api";

const initForm = {
  category: "",
  name: "",
  area: "",
  description: "",
  city: "",
  state: "",
  furnished: "",
  bathrooms: "",
  bedrooms: "",
  img: "",
  fileType: "",
  media: [],
  longitude: "",
  latitude: "",
  price: "",
};

export default function FormProperty({ isOpen = false, onClose, _id }) {
  const [form, setForm] = useState(initForm);
  const [categories, setCategories] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [category, setCategory] = useState();
  const [file, setFile] = useState();
  const [files, setFiles] = useState([]);
  const [isUpdateImg, setIsUpdateImg] = useState(false);
  const [isUpdateMedia, setIsUpdateMedia] = useState(false);
  const [type, setType] = useState();

  const fetchData = async (_id) => {
    try {
      const { category, coordinates, ...response } = await propertyAPI.getById(
        _id
      );
      setForm({
        ...response,
        latitude: coordinates?.latitude,
        longitude: coordinates?.longitude,
      });
      setFiles(response?.media);
      setFile(response?.img);
      setCategory(category?._id);
    } catch (error) {
      getError(error);
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await categoryAPI.get();
      setCategories(response?.items);
    } catch (error) {
      getError(error);
    }
  };

  const fetchState = async () => {
    try {
      const iso2 = "SA";
      const id = "194";
      const response = await stateAPI.getByCountry(iso2, id);
      setStates(response?.items);
    } catch (error) {
      getError(error);
    }
  };

  const fetchCity = async (state) => {
    try {
      const country = "SA";
      const response = await cityAPI.getByCountryAndState(country, state);
      setCities(response?.items);
    } catch (error) {
      getError(error);
    }
  };

  const init = async () => {
    try {
      setIsUpdateImg(false);
      setIsUpdateMedia(false);
      const category = fetchCategory();
      const state = fetchState();
      await category;
      await state;
      if (_id) {
        await fetchData(_id);
      }
    } catch (error) {
      getError(error);
    }
  };

  const getCategory = async (id) => {
    try {
      const response = await categoryAPI.getById(id);
      setType(response?.type);
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    let flag = true;
    if (flag && form?.state?.length > 0 && states?.length > 0) {
      const state = states.find((item) => item._id === form?.state);
      fetchCity(state?.iso2);
    }
    return () => {
      flag = false;
    };
  }, [form.state]);

  useEffect(() => {
    setForm(initForm);
  }, [isOpen]);

  useEffect(() => {
    let flag = true;
    flag === true && init();
    return () => {
      flag = false;
    };
  }, [_id]);

  useEffect(() => {
    if (category) {
      getCategory(category);
    }
  }, [category]);

  useEffect(() => {
    setFiles([]);
    setFile();
    setCategory();
  }, [onClose]);

  useEffect(() => {
    if (type && type === TYPE_CATEGORY.LAND) {
      setForm({ ...form, bathrooms: "", bedrooms: "" });
    }
  }, [type]);

  return (
    <Dialog
      maxWidth="md"
      title={"View Detail"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <TextField
              placeholder="Enter name property"
              label={"Name"}
              value={form?.name}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              variant="standard"
              label="Category"
              value={
                category && typeof category === "string"
                  ? category
                  : form?.category
              }
            >
              {categories &&
                categories?.map((category) => (
                  <MenuItem key={category?._id} value={category?._id}>
                    {category?.name}
                  </MenuItem>
                ))}
            </TextField>
          </FormControl>
        </Grid>

        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              label={
                <span>
                  Area (m<sup>2</sup>)
                </span>
              }
              variant="standard"
              value={form.area}
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              select
              label="State"
              value={form.state}
              variant="standard"
            >
              {states &&
                states?.map((state) => (
                  <MenuItem key={state?._id} value={state?._id}>
                    {state?.name}
                  </MenuItem>
                ))}
              {states?.length === 0 && (
                <MenuItem value="" disabled>
                  Not found data! Please select or change country!
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField value={form.city} select label="City" variant="standard">
              {cities &&
                cities?.map((city) => (
                  <MenuItem key={city?._id} value={city?._id}>
                    {city?.name}
                  </MenuItem>
                ))}
              {cities?.length === 0 && (
                <MenuItem value="" disabled>
                  Not found data! Please select or change state!
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        </Grid>
        {type === TYPE_CATEGORY.HOUSE && (
          <>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  label={"Bedrooms"}
                  variant="standard"
                  value={form?.bedrooms}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  label="Bathrooms"
                  value={form?.bathrooms}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  label="Furnished"
                  value={form?.furnished}
                />
              </FormControl>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControl fullWidth>
                <TextField
                  variant="standard"
                  label="Price"
                  value={form?.price || 0}
                />
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Latitude"
              value={form?.latitude}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              label="Longitude"
              value={form?.longitude}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <TextField
              variant="standard"
              multiline
              rows={5}
              label="Description"
              value={form?.description}
            />
          </FormControl>
        </Grid>

        <Grid item container md={12} spacing={2}>
          <Grid item md={12}>
            <FormControl>
              <label>Main picture</label>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Tooltip title="Main picture" placement="top">
                <label className="form-img" htmlFor="main-picture">
                  {file ? (
                    <ImageForm src={form?.img} type={form?.fileType} />
                  ) : (
                    <CameraAltIcon color="primary" sx={{ fontSize: 40 }} />
                  )}
                </label>
              </Tooltip>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container md={12} spacing={1}>
          <Grid item xs={12}>
            <FormControl>
              <label>Children picture</label>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <Tooltip title="Children picture" placement="top">
                <label className="form-img" htmlFor="children-picture">
                  <CameraAltIcon color="primary" sx={{ fontSize: 40 }} />
                </label>
              </Tooltip>
            </FormControl>
          </Grid>
          {files &&
            files?.map((file, index) => (
              <Grid item key={index}>
                <FormControl fullWidth>
                  <div className="form-img">
                    <ImageForm src={file?.url} type={file?.type} />
                    <button className="button-delete-children-picture">
                      <CloseIcon fontSize="small" />
                    </button>
                  </div>
                </FormControl>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <DialogActions sx={{ mt: 2 }}>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
