const { default: axios } = require("axios");

const path = "users/"

const create = async (user) => {
    return (await axios.post(path, user)).data;
  }
  
  const update = async (id, user) => {
    return (await axios.patch(path + id, user)).data;
  }
  
  const get = async (filter = {}) => {
    return (await axios.get(path, { params: filter })).data;
  }
  
  const getById = async (id) => {
    return (await axios.get(path + 'user/' + id)).data
  }
    
  const trash = async (id) => {
    return (await axios.delete(path + "trash/" + id)).data;
  }
    
  const _delete = async (id) => {
    return (await axios.delete(path + id)).data;
  }

  const changePassword = async (id, user) => {
    return await axios.patch(path + id + '/changePassword', user);
  }

  export const userAPI = {
    create,
    update,
    get,
    getById,
    trash,
    delete: _delete,
    changePassword
}