const { default: axios } = require("axios");

const path = "auth/";

const me = async () => {
  return (await axios.get(path + "me")).data;
};

const login = async (user) => {
  return (await axios.post(path + "login-admin", user)).data;
};

const register = async (user) => {
  return await axios.post(path + "register-admin", user);
};

export const authAPI = {
  register,
  login,
  me,
};
