import {
  Button,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Dialog from "../../components/dialog";
import ImageForm from "../../components/image/image_form";
import { getError } from "../../helper/getError";
import { useLoading } from "../../hooks/useLoading";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { TYPE_USE } from "../../consts/type_user.const";
import { userAPI } from "../../apis/user.api";

const initForm = {
  address: "",
  name: "",
  email: "",
  phone: "",
  type: TYPE_USE.NORMAL,
  follow: [],
  avatar: "",
  fileType: "",
  active: false,
};

export default function FormUser({ isOpen = false, onClose, _id, onRefetch }) {
  const { setLoading } = useLoading();
  const [form, setForm] = useState(initForm);
  const [file, setFile] = useState();
  const [type, setType] = useState([]);

  const onUpdate = async (_id) => {
    try {
      setLoading(true);
      await userAPI.update(_id, form);
      if (onRefetch) await onRefetch();
      setLoading(false);
      toast.success("Updated user success!");
      onClose();
    } catch (error) {
      getError(error);
    }
  };

  const onSubmit = () => {
    if (_id) {
      onUpdate(_id);
    }
  };

  const fetchData = async (_id) => {
    try {
      if (_id && _id !== undefined) {
        const result = await userAPI.getById(_id);
        setForm(result);
        if (result && result.avatar) {
          setFile(result.avatar);
        }
      }
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    let flag = true;
    if (flag === true) {
      setType([
        TYPE_USE.NORMAL,
        TYPE_USE.STANDARD,
        TYPE_USE.LUXURY,
        TYPE_USE.SUPREME,
      ]);
    }
    return () => {
      flag = false;
    };
  }, []);

  useEffect(() => {
    setForm(initForm);
  }, [isOpen]);

  useEffect(() => {
    let flag = true;
    if (_id && flag === true) {
      fetchData(_id);
    }
    return () => {
      flag = false;
    };
  }, [_id]);

  return (
    <Dialog
      maxWidth="md"
      title={"View Detail"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item container md={12} spacing={2}>
          <Grid item md={12}>
            <FormControl>
              <label>Avatar</label>
            </FormControl>
          </Grid>
          <Grid item md={6} xs={12}>
            <FormControl fullWidth>
              <Tooltip title="Avatar" placement="top">
                <label className="form-img" htmlFor="main-picture">
                  {file ? (
                    <ImageForm src={form?.avatar} type={form?.fileType} />
                  ) : (
                    <CameraAltIcon color="primary" sx={{ fontSize: 40 }} />
                  )}
                </label>
              </Tooltip>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <FormControl fullWidth>
              <TextField
                placeholder="Enter address"
                label={"Address"}
                value={form.address}
                variant="standard"
              />
            </FormControl>
          </FormControl>
        </Grid>
        <Grid item md={12} xs={12}>
          <FormControl fullWidth>
            <TextField
              placeholder="Enter name"
              label={"Name"}
              value={form.name}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              placeholder="Enter email"
              label={"Email"}
              value={form.email}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField
              placeholder="Enter phone"
              label={"Phone"}
              value={form.phone}
              variant="standard"
            />
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <TextField value={form.type} select label="Type" variant="standard">
              {type &&
                type.length > 0 &&
                type.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>
                      {item}
                    </MenuItem>
                  );
                })}
            </TextField>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <InputLabel>Active</InputLabel>
            <Select
              value={form.active}
              onChange={(e) => setForm({ ...form, active: e.target.value })}
              variant="standard"
            >
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <DialogActions sx={{ mt: 2 }}>
        <Button variant="contained" onClick={() => onSubmit()}>
          UPDATE
        </Button>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
