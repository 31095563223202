import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AuthLayout from "./layouts/auth";
import AdminLayout from "./layouts/admin.js";
import configAxios from "./plugins/axios";
import { ToastContainer } from "react-toastify";
import "./assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import "react-toastify/dist/ReactToastify.css";
import "../src/assets/css/custom.css";
import "./assets/scss/index.scss";

import reportWebVitals from './reportWebVitals';

configAxios();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ToastContainer />
    <BrowserRouter>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <Route path="/admin" component={AdminLayout} />
        <Redirect from="/" to="/admin/dashboard" />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();