import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Face from "@material-ui/icons/Face";
import GridContainer from "../../components/grid/gridContainer.js";
import GridItem from "../../components/grid/gridItem.js";
import CustomInput from "../../components/customInput/customInput.js";
import Button from "../../components/customButtons/button.js";
import Card from "../../components/card/card.js";
import CardBody from "../../components/card/cardBody.js";
import CardHeader from "../../components/card/cardHeader.js";
import CardFooter from "../../components/card/cardFooter.js";
import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { jwtManager } from "../../helper/jwtManager";
import configAxios from "../../plugins/axios";
import { useHistory } from "react-router-dom";
import { Lock } from "@material-ui/icons";
import { toast } from "react-toastify";
import { authAPI } from "../../apis/auth.api";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const [user, setUser] = useState({
    username: "",
    password: ""
  });
  const history = useHistory();
  const classes = useStyles();

  async function onSubmit(e) {
    e.preventDefault();
    try {
      const token = await authAPI.login(user);
      if (token?.role === "user") {
        toast.error("This account isn't role admin or brand !");
      } else {
        localStorage.setItem("role", token.role);
        jwtManager.set(token.token);
        configAxios();
        history.push("/admin/dashboard");
      }
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
  }

  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 300);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={onSubmit}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Log in</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Username"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: user.username,
                    onChange: (e) => setUser({ ...user, username: e.target.value }),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                  }}
                />
                <CustomInput
                  labelText="Password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: user.password,
                    onChange: (e) => setUser({ ...user, password: e.target.value }),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" size="lg" block type="submit">
                  Login
                </Button>
              </CardFooter>
            </Card>
          </form>

          {/* <Link to="/auth/forgot">
            <Button color="rose" simple size="lg" block type="submit">
              Forgot password
            </Button>
          </Link> */}
        </GridItem>
      </GridContainer>
    </div>
  );
}
