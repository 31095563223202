import axios from "axios";

const path = "rent/";

const create = async (rent) => {
  return (await axios.post(path, rent)).data;
};

const update = async (id, rent) => {
  return (await axios.patch(path + id, rent)).data;
};

const get = async (filter = {}) => {
  return (await axios.get(path, { params: filter })).data;
};

const getById = async (id) => {
  return (await axios.get(path + id)).data;
};

const trash = async (id) => {
  return (await axios.delete(path + "trash/" + id)).data;
};

const _delete = async (id) => {
  return (await axios.delete(path + id)).data;
};

export const rentAPI = {
  create,
  update,
  get,
  getById,
  trash,
  delete: _delete,
};