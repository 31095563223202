import { FormControl } from "@material-ui/core";
import { Button, DialogActions, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { reportListingAPI } from "../../apis/report_listing.api";
import Dialog from "../../components/dialog";
import { getError } from "../../helper/getError";
import { useLoading } from "../../hooks/useLoading";

const initForm = {
  from: "",
  to: "",
  reason: "",
  detail: "",
  isRead: "",
  listing: "",
  seller: "",
};

export default function FormReportListing({
  isOpen = false,
  onClose,
  _id,
  onRefetch,
  handleViewListing,
  handleViewSeller,
}) {
  const { setLoading } = useLoading();
  const [form, setForm] = useState(initForm);

  const fetchData = async (_id) => {
    try {
      let { from, to, ...response } = await reportListingAPI.getById(_id);
      setForm({
        ...response,
        from: from.name,
        to: to.seller.name,
        listing: to._id,
        seller: from._id,
      });
    } catch (error) {
      getError(error);
    }
  };

  const handleRead = async () => {
    try {
      setLoading(true);
      await reportListingAPI.update(_id, { isRead: true });
      if (onRefetch) await onRefetch();
      setLoading(false);
      toast.success("Success!");
      onClose();
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    setForm(initForm);
  }, [isOpen]);

  useEffect(() => {
    let flag = true;
    if (_id && flag === true) {
      fetchData(_id);
    }
    return () => {
      flag = false;
    };
  }, [_id]);

  return (
    <div>
      <Dialog title={"Report Detail"} isOpen={isOpen} onClose={onClose}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                label="Report sender"
                variant="standard"
                value={form.from}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                label="Listing of seller"
                variant="standard"
                value={form.to}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                label="Reason"
                variant="standard"
                value={form.reason}
              />
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <FormControl fullWidth>
              <TextField
                multiline
                label="Detail"
                variant="standard"
                value={form.detail || "Nothing"}
              />
            </FormControl>
          </Grid>
        </Grid>
        <DialogActions sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="warning"
            size="small"
            onClick={() => handleViewSeller(form.seller)}
          >
            View Seller
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleViewListing(form.listing)}
          >
            View Listing
          </Button>
          {form.isRead === false && (
            <Button variant="contained" size="small" onClick={handleRead}>
              Done
            </Button>
          )}
          <Button
            variant="contained"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
