import axios from "axios";

const path = "property/";

const get = async (filter = {}) =>
  (await axios.get(path, { params: filter })).data;

const getById = async (id) => (await axios.get(path + id)).data;

const create = async (property) => {
  return axios.post(path, property);
};

const update = async (id, form) => (await axios.patch(path + id, form)).data;

const trash = async (id) => (await axios.delete(path + "trash/" + id)).data;

export const propertyAPI = {
  get,
  getById,
  create,
  update,
  trash,
};
