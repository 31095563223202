import { useEffect, useState } from "react";
import { Web3Context } from "../contexts/web3Context";
import { authAPI } from "../apis/auth.api";
import { jwtManager } from "../helper/jwtManager";
import { useHistory } from "react-router-dom";
export default function Web3Provider({ children }) {
    const [provider, setProvider] = useState();
    const [signer, setSigner] = useState();
    const [user, setUser] = useState();
    const history = useHistory();

    const logout = () => {
        jwtManager.clear();
        history.push("/auth/login");
    };

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (token) {
            me();
        }
    }, []);
    async function me() {
        try {
            const user = await authAPI.me();
            setUser(user);
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Web3Context.Provider
            value={{
                user,
                signer,
                provider,
                logout,
                me
            }}
        >
            {children}
        </Web3Context.Provider>
    );
}
