import {
  Button,
  DialogActions
} from "@mui/material";
import { toast } from "react-toastify";
import { packageAPI } from "../../apis/package.api";
import { getError } from "../../helper/getError";
import { useLoading } from "../../hooks/useLoading";
import MarketPlace from '../../contract/MarketPlace.json';
import { ethers } from "ethers";


export default function AddButton({
  onClose,
  _id,
  onRefetch,
  form
}) {

  const { setLoading } = useLoading();
  const checkValidate = (form) => {
    if (form?.name?.trim().length === 0) {
      toast.error("Please enter Package's Name!");
      return false;
    }
    if (isNaN(form?.price) || form?.price <= 0) {
      toast.error("Price must be number greater than 0!");
      return false;
    }
    if (isNaN(form?.numOfDay) || form?.numOfDay <= 0) {
      toast.error("Number of days must be number greater than 0!");
      return false;
    }
    if (isNaN(form?.postLimit) || form?.postLimit <= 0) {
      toast.error("Number of Posts must be number greater than 0!");
      return false;
    }
    return true;
  };

  const onCreate = async () => {
    try {
      setLoading(true);
      const tx = await addPackage();
      if (tx?.transactionHash) {
        await packageAPI.create(form);
        onClose();
        setLoading(false);
        toast.success("Create package success!");
      }
      if (onRefetch) {
        await onRefetch();
      }
    } catch (error) {
      setLoading(false);
      getError(error);
    }
  };

  const onUpdate = async (_id) => {
    try {
      setLoading(true);
      const tx = await addPackage();
      if (tx?.transactionHash) {
        await packageAPI.update(_id, form);
        if (onRefetch) await onRefetch();
        setLoading(false);
        toast.success("Updated package success!");
        onClose();
      }
    } catch (error) {
      setLoading(false);
      getError(error);
    }
  };

  const onSubmit = () => {
    if (!checkValidate(form)) return;
    if (_id) {
      onUpdate(_id);
    } else {
      onCreate();
    }
  };

  async function addPackage() {
    try {
      // check install metamask
      if (!window.ethereum) {
        console.log("Please install MetaMask");
        return;
      }
      //login
      if (window.ethereum) {
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      //check netword
      const network = await provider.getNetwork();
      console.log("network", network.chainId, typeof (network.chainId))
      console.log("chain", process.env.REACT_APP_CHAIN_ID, typeof (process.env.REACT_APP_CHAIN_ID))
      if (network?.chainId?.toString() !== process.env.REACT_APP_CHAIN_ID) {
        toast.error("Wallet Connection is wrong Network");
        return;
      }

      const signer = await provider.getSigner();

      // get contract
      const contract = new ethers.Contract(
        process.env.REACT_APP_MARKET_ADDRESS,
        MarketPlace.abi,
        signer
      );

      //mint
      const tx = await contract.setPriceAndLimitForType(form.name, form.numOfDay, ethers.utils.parseEther(form.price), form.postLimit);
      const recipt = await tx.wait();
      console.log('recipt', recipt);
      return recipt;

    } catch (error) {
      if (error?.data?.code === -32000) {
        toast.error("You do not have enough money !");
      }else {
        toast.error("Network error");
      }

      console.log(error);
      onClose();
      setLoading(false);
    }
  }

  return (
    <DialogActions sx={{ mt: 2 }}>
      <Button variant="contained" onClick={() => onSubmit()}>
        {_id ? "UPDATE" : "ADD"}
      </Button>
      <Button variant="contained" color="inherit" onClick={onClose}>
        Cancel
      </Button>
    </DialogActions>
  );
}
