import axios from "axios";

const path = "state/";
const getByCountry = async (ios2, id) => {
  return (await axios.get(path + "country/iso2/" + ios2 + "/id/" + id)).data;
}

const getById = async (id) => {
  return (await axios.get(path + id)).data
}

export const stateAPI = {
  getByCountry,
  getById
}