import * as React from 'react';
import * as D from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide ref={ref} {...props} />;
});

export default function Dialog({ maxWidth = "xs", title = "", children, onClose, isOpen = false, ...props }) {
    return (
        <D.default
            fullWidth={true}
            maxWidth={maxWidth}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            {...props}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
        </D.default>
    );
}
