import { FILE_TYPE } from "../../consts/file_type";

export default function ImageForm({ src, alt = "", type = FILE_TYPE.IMAGE }) {
  switch (type) {
    case FILE_TYPE.IMAGE:
      return <img src={src} alt={alt} />;
    case FILE_TYPE.AUDIO:
      return;
    case FILE_TYPE.VIDEO:
      return (
        <video width="320" height="240" controls>
          <source src={src} type="video/mp4" />
        </video>
      );
    default:
      return type
  }
}