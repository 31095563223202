
import { Button, DialogActions, Typography } from "@mui/material";
import Dialog from ".";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
export default function DeleteDialog({ isOpen, title = "", onSubmit, onClose }) {
  return (
    <Dialog isOpen={isOpen}>
      <div className="d-flex justify-content-center align-items-center">
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography> <CancelOutlinedIcon color="error" sx={{ fontSize: 80 }} />
      </div>
      <DialogActions>
        <Button variant="contained" color="info" onClick={() => onSubmit()}>Delete</Button>
        <Button variant="contained" color="inherit" onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}