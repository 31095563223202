import Group from "@material-ui/icons/Group";
import ForgotPage from "./views/auth/forgot_page";
import Category from "./views/category";
import Package from "./views/package";
import Property from "./views/property/index.js";
import User from "./views/user/index.js";
import Listing from "./views/listing/index.js";
import UserProfile from "./views/auth/user_profile";
import LoginPage from "./views/auth/login_page";
import ReportListing from "./views/report_listing";
import RegisterPage from "./views/auth/register_page";
import Rent from "./views/rent";

var dashRoutes = [
  {
    hide: true,
    collapse: true,
    name: "Auth",
    views: [
      {
        path: "/login",
        name: "Login",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/forgot",
        name: "Forgot",
        component: ForgotPage,
        layout: "/auth",
      },
    ],
  },

  // =================================================================== USER
  {
    path: "/profile",
    name: "Profile",
    component: UserProfile,
    layout: "/admin",
  },
  //====================================================================CATEGORY
  {
    isMenu: true,
    path: "/Category",
    name: "Category",
    component: Category,
    layout: "/admin",
    icon: Group,
  },

  //====================================================================PROPERTY
  {
    isMenu: true,
    path: "/Property",
    name: "Property",
    component: Property,
    layout: "/admin",
    icon: Group,
  },
  //====================================================================LISTING
  {
    isMenu: true,
    path: "/Listing",
    name: "Listing",
    component: Listing,
    layout: "/admin",
    icon: Group,
  },
   //====================================================================LISTING
   {
    isMenu: true,
    path: "/Rent",
    name: "Rent",
    component: Rent,
    layout: "/admin",
    icon: Group,
  },

   //====================================================================PACKAGE
   {
    isMenu: true,
    path: "/package",
    name: "Package",
    component: Package,
    layout: "/admin",
    icon: Group,
  },
  
 
  //====================================================================USER
  {
    isMenu: true,
    path: "/User",
    name: "User",
    component: User,
    layout: "/admin",
    icon: Group,
  },
  //====================================================================MESSAGE
  // {
  //   isMenu: true,
  //   path: "/Message",
  //   name: "Message",
  //   component: Category,
  //   layout: "/admin",
  //   icon: Group,
  // },
  //====================================================================REPORT OF USER
  // {
  //   isMenu: true,
  //   path: "/report-of-user",
  //   name: "Report of User",
  //   component: Category,
  //   layout: "/admin",
  //   icon: Group,
  // },
  //====================================================================REPORT OF LISTING
  {
    isMenu: true,
    path: "/report-of-listing",
    name: "Report of Listing",
    component: ReportListing,
    layout: "/admin",
    icon: Group,
  },
  {
    path: "/dashboard",
    name: "Category",
    component: Category,
    layout: "/admin",
    icon: Group,
  },
];
export default dashRoutes;
