import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import GridContainer from "../../components/grid/gridContainer";
import GridItem from "../../components/grid/gridItem.js";
import Button from "../../components/customButtons/button.js";
import CustomInput from "../../components/customInput/customInput.js";
import Card from "../../components/card/card.js";
import CardBody from "../../components/card/cardBody.js";
import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import CardFooter from "../../components/card/cardFooter";
import CardHeader from "../../components/card/cardHeader.js";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { authAPI } from "../../apis/auth.api";
const useStyles = makeStyles(styles);

export default function RegisterPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  const classes = useStyles();
  const history = useHistory();
  const [user, setUser] = useState({
    username: "",
    password: "",
    confirm: ""
  });
  const checkValidate = () => {
    if (user.username.length === 0) {
      toast.error("Please enter username!");
      return false;
    }
    if (user.password.length === 0) {
      toast.error("Please enter password!");
      return false;
    }
    if (user.confirm !== user.password) {
      toast.error("Confirm password not equal password!");
      return false;
    }
    return true;
  };

  async function onSubmit(e) {
    e.preventDefault();
    try {
      if (!checkValidate()) return;
      console.log(user)
      await authAPI.register(user);
      toast.success("Register success !");
      history.push("/auth/login");
    } catch (e) {
      console.log(e);
      toast.error(e.message);
    }
  }


  // animation
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 300);
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

  return (
    <div className={classes.container}>
      <GridContainer justifyContent="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={onSubmit}>
            <Card className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}> Register </h4>
              </CardHeader>

              <CardBody>

                <CustomInput
                  labelText="User Name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: user.username,
                    onChange: (e) => setUser({ ...user, username: e.target.value }),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "text",
                    autoComplete: "off",
                  }}
                />

                <CustomInput
                  labelText="Password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: user.password,
                    onChange: (e) => setUser({ ...user, password: e.target.value }),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
                <CustomInput
                  labelText="Confirm password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: user.confirm,
                    onChange: (e) => setUser({ ...user, confirm: e.target.value }),
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button color="rose" simple size="lg" block type="submit">
                  Register
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
