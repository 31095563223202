import axios from "axios";

const path = "report-listing/";

const create = async (payload) => {
  return (await axios.post(path, payload)).data;
}

const update = async (id, payload) => {
  return (await axios.patch(path + id, payload)).data;
}

const get = async (filter = {}) => {
  return (await axios.get(path, { params: filter })).data;
}

const getById = async (id) => {
  return (await axios.get(path + id)).data
}
  
const trash = async (id) => {
  return (await axios.delete(path + "trash/" + id)).data;
}
  
const _delete = async (id) => {
  return (await axios.delete(path + id)).data;
}

export const reportListingAPI = {
    create,
    update,
    get,
    getById,
    trash,
    delete: _delete
}