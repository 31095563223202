import { FormControl } from "@material-ui/core";
import {
  Button,
  DialogActions,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { categoryAPI } from "../../apis/category.api";
import Dialog from "../../components/dialog";
import { TYPE_CATEGORY } from "../../consts/type_category.const";
import { getError } from "../../helper/getError";
import { useLoading } from "../../hooks/useLoading";

const initForm = {
  name: "",
  arabic_name: "",
  type: TYPE_CATEGORY.HOUSE,
};

export default function FormCategory({
  isOpen = false,
  onClose,
  _id,
  onRefetch,
}) {
  const { setLoading } = useLoading();
  const [form, setForm] = useState(initForm);

  const checkValidate = (form = initForm) => {
    if (form?.type?.length === 0) {
      toast.error("Please enter type category!");
      return false;
    }
    if (form?.name?.length === 0) {
      toast.error("Please enter name!");
      return false;
    }
    if (form?.arabic_name?.length === 0) {
      toast.error("Please enter name in Arabic!");
      return false;
    }
    return true;
  };

  const onCreate = async () => {
    try {
      setLoading(true);
      await categoryAPI.create(form);
      if (onRefetch) {
        await onRefetch();
      }
      onClose();
      setLoading(false);
      toast.success("Create category success!");
    } catch (error) {
      setLoading(false);
      getError(error);
    }
  };

  const onUpdate = async (_id) => {
    try {
      setLoading(true);
      await categoryAPI.update(_id, form);
      if (onRefetch) await onRefetch();
      setLoading(false);
      toast.success("Updated category success!");
      onClose();
    } catch (error) {
      setLoading(false);
      getError(error);
    }
  };

  const onSubmit = () => {
    if (!checkValidate(form)) return;
    if (_id) {
      onUpdate(_id);
    } else {
      onCreate();
    }
  };

  const fetchData = async (_id) => {
    try {
      setForm(await categoryAPI.getById(_id));
    } catch (error) {
      getError(error);
    }
  };

  useEffect(() => {
    setForm(initForm);
  }, [isOpen]);

  useEffect(() => {
    let flag = true;
    if (_id && flag === true) {
      fetchData(_id);
    }
    return () => {
      flag = false;
    };
  }, [_id]);

  return (
    <Dialog
      title={_id ? "Update Category" : "Add Category"}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControl fullWidth>
            <InputLabel>Type</InputLabel>
            <Select
              value={form.type}
              onChange={(e) => setForm({ ...form, type: e.target.value })}
              variant="standard"
            >
              <MenuItem value={TYPE_CATEGORY.HOUSE}>House</MenuItem>
              <MenuItem value={TYPE_CATEGORY.LAND}>Land</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              required
              label="Name"
              variant="standard"
              value={form.name}
              onChange={(e) => setForm({ ...form, name: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item md={12}>
          <FormControl fullWidth>
            <TextField
              autoFocus
              required
              label="Name in Arabic"
              variant="standard"
              value={form.arabic_name}
              onChange={(e) => setForm({ ...form, arabic_name: e.target.value })}
            />
          </FormControl>
        </Grid>
      </Grid>
      <DialogActions sx={{ mt: 2 }}>
        <Button variant="contained" onClick={() => onSubmit()}>
          {_id ? "UPDATE" : "ADD"}
        </Button>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
